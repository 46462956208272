
const storage = {
    setStorage(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    getStorage(key: string) {
        return JSON.parse(localStorage.getItem(key) as string)
    },
    remove(key: string) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear()
    },
    setSession(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    getSession(key: string) {
        return JSON.parse(sessionStorage.getItem('key') as string)
    },
    removeSession(key: string) {
        sessionStorage.removeItem(key)
    },
    clearSession() {
        sessionStorage.clear();
    }
}

export default storage;