
export default {
    path: '/card',
    name: 'Card',
    component: () => import('@/views/base/home.vue'),
    redirect: "/card/cardManage",
    show: true,
    meta: {
        title: '卡券管理',
        requireAuth: true, //判断是否需要登录
        icon: 'Postcard'
    },
    children: [
        {
            path: 'cardManage',
            name: 'CardManage',
            component: () => import('../views/cardManage/cardManage.vue'),
            meta: {
                title: '卡券管理',
                requireAuth: true
            }
        }
    ]
}