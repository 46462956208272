export default {
    path: '/system',
    name: 'System',
    component: () => import('@/views/base/home.vue'),
    redirect: "/system/account",
    show: true,
    meta: {
        title: '系统管理',
        requireAuth: true, //判断是否需要登录
        icon: 'Setting'
    },
    children: [
        {
            path: 'account',
            name: 'Account',
            component: () => import('../views/systemManage/account/index.vue'),
            redirect: "/system/account/accountManage",
            meta: {
                title: '账户管理',
                requireAuth: true
            },
            children: [
                {
                    path: 'accountManage',
                    name: 'AccountManage',
                    component: () => import('../views/systemManage/account/accountManage.vue'),
                    meta: {
                        title: '账户列表',
                        requireAuth: true
                    }
                },
                {
                    path: 'addAccount',
                    name: 'AddAccount',
                    component: () => import('../views/systemManage/account/addAccount.vue'),
                    meta: {
                        title: '创建账户',
                        requireAuth: true,
                        adminType: 1//权限为1的才能访问
                    }
                },
            ]
        },
        {
            path: 'version',
            name: 'Version',
            component: () => import('../views/systemManage/version/index.vue'),
            redirect: "/system/version/versionManage",
            meta: {
                title: '版本管理',
                requireAuth: true
            },
            children: [
                {
                    path: 'versionManage',
                    name: 'VersionManage',
                    component: () => import('../views/systemManage/version/versionManage.vue'),
                    meta: {
                        title: '版本列表',
                        requireAuth: true
                    },
                },
                {
                    path: 'addVersion',
                    name: 'AddVersion',
                    component: () => import('../views/systemManage/version/addVersion.vue'),
                    meta: {
                        title: '添加版本',
                        requireAuth: true
                    }
                }
            ]
        },

    ]
}