import { defineStore } from "pinia";
import { ref } from "vue";
import { userInfoType } from "../utils/commonType";
import router from "@/router";
import storage from "@/utils/storage";

export const useUserStore = defineStore('useUserStore', () => {
    type userType = userInfoType | null;
    // 用户信息
    let user_info = ref<userType>();
    // token数据
    let token = ref<string>('');
    // 用户userId
    let userId = ref<number | string>('');

    // 退出登录
    const loginOut = (): void => {
        user_info.value = null;
        token.value = '';
        userId.value = '';
        storage.clearSession();
        router.replace('/login');
    }

    return {
        user_info,
        token,
        userId,
        loginOut
    }
},
    // 配置是否持久化存储
    {
        persist: {
            enabled: true,
            // strategies自定义存储内容
            // key:自定义存储的 key;storage:默认是 sessionStorage;paths:state 中的字段名
            // strategies: [
            //     {
            //         key: 'user_info',
            //         storage: sessionStorage,
            //         paths: ["user_info"]
            //     },
            //     {
            //         key: 'token',
            //         storage: sessionStorage,
            //         paths: ["token"]
            //     },
            //     {
            //         key: 'userId',
            //         storage: sessionStorage,
            //         paths: ["userId"]
            //     }
            // ]
        }
    }
)