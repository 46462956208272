import { createApp, toRaw } from 'vue'
import App from './App.vue'
// 引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import 'default-passive-events';
//引入路由文件
import router from './router';
// 引入非功能性样式
import '@/static/css/index.scss';
// 引入storage文件
import storage from './utils/storage';
// 引入pinia状态管理器
import { createPinia } from 'pinia';
// pinia数据持久化存储
import piniaPersist from 'pinia-plugin-persist'
// 导入总线注册store
import { registerStore } from './store'
const pinia = createPinia();
pinia.use(piniaPersist);

// 项目环境
const isProd = import.meta.env.VITE_ENV === 'production' ? true : false;


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(router)
app.use(ElementPlus, {
    locale: zhCn
})
app.use(pinia)
// 注册pinia状态管理器
registerStore();
app.mount('#app')