import { useUserStore } from "./useUserStore";

interface IAppStore {
    useUserStore: ReturnType<typeof useUserStore>;
}

const appStore: IAppStore = {} as IAppStore;

// 注册app状态库
export const registerStore = () => {
    appStore.useUserStore = useUserStore()
}

export default appStore