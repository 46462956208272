<template>
    <div class="sider-container">
        <div class="user-block">
            <div class="headImg-box">
                <img src="@/static/images/defaultAdminImg.png" alt="用户头像">
            </div>
            <div class="user-name">{{ user_info.userName }}</div>
            <div class="desc">大班后台管理系统</div>
        </div>
        <!-- menu列表 -->
        <div class="menu-block">
            <el-menu default-active="2" class="el-menu-vertical-demo" background-color="transparent" text-color="#fff"
                active-text-color="#fff34b">
                <el-menu-item index="bench" @click="goBench">
                    <el-icon>
                        <Menu />
                    </el-icon>
                    <span>中心首页</span>
                </el-menu-item>
                <component v-for="(item, index) in menuList" :key="item.name" :index="item.name"
                    :is="item.children && item.children.length > 0 ? 'el-sub-menu' : 'el-menu-item'"
                    @click="handleMenu(item)">
                    <template #title>
                        <el-icon>
                            <component :is="item.meta!.icon"></component>
                        </el-icon>
                        <span>{{ item.meta!.title }}</span>
                    </template>
                    <el-menu-item v-for="(sItem, sIndex) in item.children" :index="sItem.name" :key="sItem.name"
                        @click="handleMenu(sItem)">
                        {{ sItem.meta!.title }}
                    </el-menu-item>
                </component>
            </el-menu>
        </div>
        <!-- 版本号 -->
        <div class="version-text">版本号：{{ version }}</div>
        <!-- 背景泡泡 -->
        <div class="l-bubble"></div>
        <div class="m-bubble"></div>
        <div class="s-bubble"></div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import useCurrentInstance from '@/utils/useCurrentInstance';
import { userInfoType } from "@/utils/commonType";
import appStore from "@/store";
const user_info = appStore.useUserStore.user_info as userInfoType;
// 获取版本号
const { globalProperties } = useCurrentInstance();
let version: string = globalProperties.version
// 获取菜单
const $router = useRouter();
const menuList = computed(() => {
    return $router.options.routes.filter((item) => item.show)
})

// 点击菜单
const handleMenu = ((item: any) => {
    if (item.show) {
        return false
    }
    $router.push({
        name: item.name
    });
})

// 跳转到工作台
const goBench = () => {
    $router.push({
        name: 'Bench'
    })
}

</script>


<style lang="scss" scoped>
.sider-container {
    min-width: 200px;
    width: 200px;
    min-height: 100vh;
    background: radial-gradient(200% 100% at bottom center,
            #5D9FFF,
            #5D9FFF,
            #6BBBFF,
        );
    position: relative;
    padding: 16px;
    overflow: hidden;

    // 用户信息部分
    .user-block {
        .headImg-box {
            width: 60px;
            margin: 0 auto 10px;

            &>img {
                width: 100%;
                height: 100%;
            }
        }

        .user-name,
        .desc {
            color: $white;
            text-align: center;
            margin-bottom: 10px;
        }

        .desc {
            font-size: 14px;
        }
    }


    // 菜单部分
    .menu-block {
        width: 100%;
        height: calc(100vh - 200px);
        overflow-y: auto;
        position: relative;
        z-index: 5;
    }

    .version-text {
        color: $white;
        text-align: center;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
    }

    // 背景泡泡
    .l-bubble,
    .m-bubble,
    .s-bubble {
        border-radius: 50%;
        color: #fff;
        background: radial-gradient(200% 100% at top center,
                rgba(255, 255, 255, 0.3),
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0),
            );
        width: 30px;
        height: 30px;
        overflow: hidden;
        position: absolute;
        z-index: 0;
    }

    .l-bubble {
        width: 200px;
        height: 200px;
        left: -60px;
        bottom: 80px;
        animation: bubbleRotate 5s infinite ease-in-out;
    }

    .m-bubble {
        bottom: 280px;
        right: 30px;
        animation: bubbleRotate 5s infinite ease-in-out;
    }

    .s-bubble {
        width: 50px;
        height: 50px;
        top: 0;
        right: -28px;
    }
}

@keyframes bubbleRotate {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}

// 侧边栏样式
:deep(.el-menu) {
    border-right: none !important;
}

:deep(.el-sub-menu .el-menu-item) {
    min-width: auto !important;
}

:deep(.el-menu-item:hover) {
    background-color: transparent !important;
}
</style>