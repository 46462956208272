<template>
    <div class="header-container">
        <div class="left-block">
            <el-breadcrumb :separator-icon="ArrowRight">
                <el-breadcrumb-item v-for="(item, index) in breadList" :key="item.name" :to="{ path: item.path }">
                    {{ item.meta.title }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="right-block" @click="loginOut">
            <el-icon :size="20" color="#409eff">
                <SwitchButton />
            </el-icon>
            <div class="icon-text">退出登录</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import appStore from "@/store";
import { ArrowRight } from '@element-plus/icons-vue'
import { userInfoType } from "@/utils/commonType";
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
const user_info = appStore.useUserStore.user_info as userInfoType;
const $router = useRouter();
const $route = useRoute();
// 面包屑
const breadList = computed(() => {
    return $route.matched
})
// 退出登录
const loginOut = () => {
    appStore.useUserStore.loginOut();
}

</script>

<style lang="scss" scoped>
.header-container {
    width: 100%;
    height: calc(100% - 10px);
    padding: 0 32px;
    box-shadow: 0 10px 5px -5px #eeeeee;
    @include flex-row(space-between, center);

    .left-block {
        height: 100%;
        @include flex-row(flex-start, center);

    }

    .right-block {
        cursor: pointer;
        @include flex-row(flex-start, center);

        .icon-text {
            padding-left: 8px;
            font-size: 14px;
        }
    }

    .right-block:hover>.icon-text {
        color: $primary-color;
    }

}
</style>