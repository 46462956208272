/**
 * 获取挂载的全局变量
 */

import { ComponentInternalInstance, getCurrentInstance } from "vue";
import { isAdmin } from './tool';

export default function useCurrentInstance() {
    const { appContext } = getCurrentInstance() as ComponentInternalInstance;
    const globalProperties = appContext.config.globalProperties
    // 设置全局版本号
    globalProperties.version = '1.0.0';
    // 判断是否管理员
    globalProperties.isAdmin = isAdmin;
    return {
        globalProperties
    }
}