<template>
    <div class="main-container">
        <div class="l-main-container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.main-container {
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow: auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px 5px #f8f8f8;

    .l-main-container {
        min-width: 680px;
        overflow: auto;
    }
}
</style>