import { createRouter, createWebHashHistory, RouteRecordRaw, _RouteRecordBase } from "vue-router"
import Home from './../views/base/home.vue';
import userManage from "./userManage";
import cardManage from "./cardManage";
import systemManage from "./systemManage";
import appStore from "@/store";
import { isAdmin } from "@/utils/tool";
// show表示展现在menu

declare module 'vue-router' {
    interface _RouteRecordBase {
        show?: boolean
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/bench',
        meta: {
            // 判断是否需要登录
            requireAuth: true,
            title: '首页'
        },
        children: [
            {
                path: '/bench',
                name: 'Bench',
                component: () => import('@/views/base/bench.vue'),
                meta: {
                    // 判断是否需要登录
                    requireAuth: true,
                    title: '工作台'
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/base/login.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/base/404.vue'),
        meta: {
            title: '404页面'
        }
    },
    // 用户管理
    userManage,
    // 卡券管理
    cardManage,
    // 系统管理
    systemManage,
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    // 判断是否有登录权限，没有跳转到登录页面
    if (to.matched.some(item => item.meta.requireAuth)) {
        if (appStore.useUserStore.token) {
            // 判断是否只有admin权限才能访问的页面
            if (to.meta.adminType == 1 && !isAdmin()) {
                next({ name: '404' })
            } else {
                next()
            }
        } else {
            next('Login');
        }
    } else {
        next()
    }

})

export default router