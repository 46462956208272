
export default {
    path: '/user',
    name: 'User',
    component: () =>  import('@/views/base/home.vue'),
    redirect: "/user/userManage",
    show: true,
    meta: {
        title: '用户管理',
        requireAuth: true, //判断是否需要登录
        icon: 'User'
    },
    children: [
        {
            path: 'userManage',
            name: 'UserManage',
            component: () => import('../views/userManage/userManage.vue'),
            meta: {
                title: '用户管理',
                requireAuth: true
            }
        },
        {
            path: 'dealManage',
            name: 'DealManage',
            component: () => import('../views/userManage/dealManage.vue'),
            meta: {
                title: '成交管理',
                requireAuth: true
            }
        }
    ]
}