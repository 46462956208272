import appStore from "@/store";
import { fa } from "element-plus/es/locale";
import moment from "moment";

// 获取YYYY-MM-DD日期格式
export const dateTime = (date: any): string => {
    return moment(date).format('YYYY-MM-DD') || '-'
}

// 获取HH:mm时间格式
export const HourMinute = (date: any): string => {
    return moment(date).format('HH:mm') || '-'
}

// 获取YYYY-MM-DD HH:mm完整时间
export const completeTime = (date: any): string => {
    if ([null, undefined, ""].includes(date)) {
        return "-"
    } else {
        return moment(new Date(date)).format('YYYY-MM-DD HH:mm')
    }
}

// element-plus中快捷选择日期数据
interface eDateCut {
    text: string,
    value: object
}
export const Eshortcuts = (): Array<eDateCut> => {
    return [
        {
            text: '最近一周',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                return [start, end]
            },
        },
        {
            text: '最近一个月',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                return [start, end]
            },
        },
        {
            text: '最近三个月',
            value: () => {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                return [start, end]
            },
        },
    ]
}


// 获取默认用户头像
const defaultClientHeadImg = 'https://shinedata-edu.oss-cn-shanghai.aliyuncs.com/school_official/miniprogram/head.png'
export const defaultHeadImg = (val: any): string => {
    if ([null, undefined, ""].includes(val)) {
        return defaultClientHeadImg
    } else {
        return val
    }
}

// 是否是admin权限的管理员
// adminType 1-admin超级管理员 2-普通管理员 3-普通账号
export const isAdmin = (): boolean => {
    const adminType = appStore.useUserStore.user_info?.adminType;
    return adminType == 1 ? true : false
}